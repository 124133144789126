@import './mixins/all.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Archivo', sans-serif;
}

html {
  font-size: 62.5%;

  @include tablet {
    font-size: 68.75%;
  }

  @include laptop {
    font-size: 75%;
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/* INPUT FIXES */
input {
  border-radius: 0px;
}

textarea:focus,
input:focus {
  outline: none;
}
